<template>
  <div>
    <organisms-edito-carousel v-if="sliceData" v-bind="sliceData" />
    <molecules-ui-pagination
      v-if="sliceData?.withPagination"
      :pages="sliceData?.totalPages"
      :current-page="Number(route.query.page || 1)"
    />
  </div>
</template>

<script setup>
import { getSliceComponentProps } from '@prismicio/vue';
import cardsListAdapter from '@/slices/CardsList/adapter';
import {
  useI18n,
  useRoute,
  usePrismic,
  useNuxtApp,
  useAsyncData,
} from '#imports';

const props = defineProps(
  getSliceComponentProps(['slice', 'index', 'slices', 'context']),
);

const i18n = useI18n();
const route = useRoute();
const { client: $prismic } = usePrismic();
const { $enhancedLinkSerializer } = useNuxtApp();

const { data: sliceData } = await useAsyncData(
  `cardsList-${props.slice.id}`,
  async () => {
    const page = parseInt(route.query.page) || 1;
    return await cardsListAdapter(props.slice, {
      pageId: props.context.id,
      page,
      prismic: $prismic,
      i18n,
      enhancedLinkSerializer: $enhancedLinkSerializer,
    });
  },
  {
    watch: [() => route.query.page],
  },
);
</script>
